.title {
    font-size: 21pt;
}

.floatLeft {
    float: left;
}

.floatRight {
    float: right;
}

.overflowAuto {
    overflow: auto;
}