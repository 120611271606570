@import url(https://rsms.me/inter/inter.css);
.title {
    font-size: 21pt;
}

.floatLeft {
    float: left;
}

.floatRight {
    float: right;
}

.overflowAuto {
    overflow: auto;
}
/********************************************
	BREAKPOINT WIDTHS
********************************************/
/********************************************
	FONTS
********************************************/
/********************************************
	COLOURS
********************************************/
.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    -webkit-transition: all 0.25s ease-in;
    transition: all 0.25s ease-in;
    opacity: 0.4;
    filter: alpha(opacity=40);
    position: absolute;
    z-index: 2;
    top: 20px;
    background: none;
    border: 0;
    font-size: 32px;
    cursor: pointer;
}

.carousel .control-arrow:hover {
    opacity: 1;
    filter: alpha(opacity=100);
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    content: '';
}

.carousel .control-disabled.control-arrow {
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: inherit;
    display: none;
}

.carousel .control-prev.control-arrow {
    left: 0;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #1D252D;
}

.carousel .control-next.control-arrow {
    right: 0;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid #1D252D;
}

.carousel {
    position: relative;
    width: 100%;
}

.carousel * {
    box-sizing: border-box;
}

.carousel img {
    width: 100%;
    display: inline-block;
    pointer-events: none;
}

.carousel .carousel {
    position: relative;
}

.carousel .control-arrow {
    outline: 0;
    border: 0;
    background: none;
    top: 50%;
    margin-top: -13px;
    font-size: 18px;
}

.carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden;
}

.carousel .thumbs {
    -webkit-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap;
}

.carousel .thumb {
    -webkit-transition: border 0.15s ease-in;
    transition: border 0.15s ease-in;
    display: inline-block;
    width: 80px;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid #1D252D;
    padding: 2px;
}

.carousel .thumb:focus {
    border: 3px solid #ccc;
    outline: none;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
    border: 3px solid rgb(236, 236, 236);
}

.carousel .thumb img {
    vertical-align: top;
}

.carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden;
}

.carousel.carousel-slider .control-arrow {
    top: 0;
    color: #1D252D;
    font-size: 26px;
    bottom: 0;
    margin-top: 0;
    padding: 5px;
}

.carousel.carousel-slider .control-arrow:hover {
    background: rgba(0, 0, 0, 0.2);
}

.carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    -webkit-transition: height 0.15s ease-in;
    transition: height 0.15s ease-in;
}

.carousel .slider-wrapper.axis-horizontal .slider {
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
    flex-direction: column;
    flex-flow: column;
}

.carousel .slider-wrapper.axis-vertical {
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}

.carousel .slider-wrapper.axis-vertical .slider {
    flex-direction: column;
}

.carousel .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%;
}

.carousel .slider.animated {
    -webkit-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}

.carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
    background: #fff;
}

.carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
}

.carousel .slide iframe {
    display: inline-block;
    width: calc(100% - 80px);
    margin: 0 40px 40px;
    border: 0;
}

.carousel .slide .legend {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    border-radius: 10px;
    background: #fff;
    color: #1D252D;
    /* padding: 0; */
    /* padding: 10px; */
    font-size: 12px;
    text-align: center;
    opacity: 0.25;
    -webkit-transition: opacity 0.35s ease-in-out;
    transition: opacity 0.35s ease-in-out;
}

.slide {
    padding: 0;
    padding-bottom: 40px
}

.carousel .control-dots {
    position: absolute;
    bottom: 0;
    margin: 10px 0;
    text-align: center;
    width: 100%;
    padding: 0;
}

@media (min-width: 960px) {
    .carousel .control-dots {
        bottom: 0;
    }
}

.carousel .control-dots .dot {
    -webkit-transition: opacity 0.25s ease-in;
    transition: opacity 0.25s ease-in;
    opacity: 0.3;
    filter: alpha(opacity=30);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    background: #1D252D;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
    opacity: 1;
    filter: alpha(opacity=100);
}

.carousel .carousel-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    color: #1D252D;
    font-family: 'Inter', sans-serif;
}

.carousel:hover .slide .legend {
    opacity: 1;
}
.pagination {
    padding: 0;
    margin: 0;
}

.pagination>li {
    display: inline-block;
    padding-left: 0;
}

.pagination>li {
    list-style: none;
}

.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding: 6px 12px;
    /* line-height: 1.42857143; */
    text-decoration: none;
    color: #2c689c;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
}

.pagination>li.active>a {
    color: #fff;
    background-color: #006C9E;
    border-color: #006C9E;
}

/* Style the active class (and buttons on mouse-over) */
.pagination>li>a:hover {
    background-color: #006C9E;
    color: white;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    margin-left: 0;
    padding: 0px;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    display: none !important;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-bottom-right-radius: 4px;
    margin-right: 0;
    padding: 0px !important;
    border-top-right-radius: 4px;
    display: none !important;
}

/* .pagination-side-buttons {
    position: relative;
    background-color: #006C9E;
    color: #17A279;
} */
.WelcomeContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.vertical-center {
    position: absolute;
    top: 50%;
}
.conversation-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
  /* border: solid 1px #c7c7cc, */
}

.conversation-list-item:hover {
  background: #eeeef1;
  cursor: pointer;
}

.conversation-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
  z-index: 1;
}

.conversation-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
}

.conversation-snippet {
  font-size: 14px;
  color: #888;
  margin: 0;
}
.compose {
  padding: 10px;
  display: flex;
  align-items: center;
  background: white;
  border-top: 1px solid #eeeef1;
  /* position: fixed; */
  width: calc(100% - 20px);
  bottom: 0px;
}

@supports ((-webkit-backdrop-filter: blur(20px)) or (backdrop-filter: blur(20px))) {
  .compose {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
  }
}

.compose-input {
  flex: 1 1;
  border: none;
  font-size: 14px;
  height: 40px;
  background: none;
}

.compose-input::-webkit-input-placeholder {
  opacity: 0.3;
}

.compose-input::-moz-placeholder {
  opacity: 0.3;
}

.compose-input:-ms-input-placeholder {
  opacity: 0.3;
}

.compose-input::-ms-input-placeholder {
  opacity: 0.3;
}

.compose-input::placeholder {
  opacity: 0.3;
}

.compose .toolbar-button {
  color: #bbbbbf;
  margin-left: 15px;
}

.compose .toolbar-button:hover {
  color: #99999c;
}
.message {
  display: flex;
  flex-direction: column;
}

.message .timestamp {
  display: flex;
  justify-content: center;
  color: #999;
  font-weight: 600;
  font-size: 12px;
  margin: 10px 0px;
  text-transform: uppercase;
}

.message .bubble-container {
  font-size: 14px;
  display: flex;
}

.message.mine .bubble-container {
  justify-content: flex-end;
}

.message.start .bubble-container .bubble {
  /* margin-top: 10px; */
  border-top-left-radius: 20px;
}

.message.end .bubble-container .bubble {
  border-bottom-left-radius: 20px;
  /* margin-bottom: 10px; */
}

.message.mine.start .bubble-container .bubble {
  margin-top: 10px;
  border-top-right-radius: 20px;
}

.message.mine.end .bubble-container .bubble {
  border-bottom-right-radius: 20px;
  margin-bottom: 10px;
}

.message .bubble-container .bubble {
  margin: 1px 0px;
  background: #f4f4f8;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 75%;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.message.mine .bubble-container .bubble {
  background: #007aff;
  color: white;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
html {
  font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

.pangeaBlue {
  background-color: #76abc0;
  color: #76abc0;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
